import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { isMultiProduct$, getIsMultiProduct } = productService;
export const useIsMultiProduct = (unsubscribe$: Subject<void>): boolean => useObservableSubscription({
  observable$: isMultiProduct$,
  initialValue: getIsMultiProduct(),
  unsubscribe$
});
