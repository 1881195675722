/**
 * Code based on page.js
 * https://github.com/visionmedia/page.js
 */

'use strict';

import { Plugin, PluginFactory, Router } from 'router5';

export const linkInterceptor = (
  opts?: object | ((name: string, params: object) => object),
  cb?: (error: any) => void
): PluginFactory => (router?: Router): Plugin => {
  const clickEvent = document.ontouchstart ? 'touchstart' : 'click';
  const clickHandler = onClick(router, opts, cb) as EventListenerOrEventListenerObject;

  return {
    onStart: () => {
      document.addEventListener(clickEvent, clickHandler, false);
    },
    onStop: () => {
      document.removeEventListener(clickEvent, clickHandler);
    }
  };
};

const merge = (object: Record<string, any>, other: Record<string, any>) => {
  const merged: Record<string, any> = {};
  Object.keys(object || {}).forEach((key: string) => {
    merged[key] = object[key];
  });
  Object.keys(other || []).forEach((key: string) => {
    merged[key] = other[key];
  });

  return merged;
};

const onClick = (
  router?: Router,
  opts?: object | ((name: string, params: object) => object),
  cb?: (error: any) => void
) => {
  if (!router) {
    return;
  }
  const which = (event: MouseEvent) => {
    event = event || window.event;
    return null === event.which
      ? event.button
      : event.which;
  };

  function getParams(href: string) {
    const params: Record<string, any> = {};
    const splitHref = href.split('?');

    if (splitHref[1] && splitHref[1].length) {
      splitHref[1].split('&')
        .forEach((param: string) => {
          const i = param.indexOf('=');

          if (i === -1 || i === param.length - 1) {
            params[decodeURIComponent(param)] = '';
            return;
          }

          const name = decodeURIComponent(param.substr(0, i));
          const value = decodeURIComponent(param.substr(i + 1));
          params[name] = value;
        });
    }

    return params;
  }

  const onclick = (event: MouseEvent) => {
    if (1 !== which(event)) {
      return;
    }
    if (event.metaKey || event.ctrlKey || event.shiftKey) {
      return;
    }
    if ((event as any).defaultPrevented) {
      return;
    }
    // ensure link
    let element = event.target as HTMLAnchorElement;
    while (element && 'A' !== element.nodeName) {
      element = element.parentNode as HTMLAnchorElement;
    }
    if (!element || 'A' !== element.nodeName) {
      return;
    }

    // Ignore if tag has
    // 1. "download" attribute
    // 2. rel="external" attribute
    if (element.hasAttribute('data-link') || element.hasAttribute('download') || element.getAttribute('rel') === 'external') {
      return;
    }

    // check target
    if (element.target) {
      return;
    }

    if (!element.href) {
      return;
    }

    const toRouteState = router.matchUrl(element.href);
    if (toRouteState) {
      event.preventDefault();
      const name = toRouteState.name;
      const params = merge(getParams(element.href), toRouteState.params);

      let finalOpts;
      if (typeof opts === 'function') {
        finalOpts = opts(name, params);
      } else {
        finalOpts = opts;
      }

      router.navigate(name, params, finalOpts, cb);
    }
  };
  return onclick;
};
