import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  RouteState,
  PageType,
  errorParameterName,
  getRedirectParameters
} from '@router';

const check = (toState: RouteState): boolean | Promise<boolean> => {
  if (!toState.isLoggedIn) {
    const name = 'login';
    const params = getRedirectParameters({
      toState,
      parameters: {
        [errorParameterName]: 401
      }
    });
    return Promise.reject({ redirect: { name, params } });
  }
  return true;
};

export const authorizationGuard: ActivationFnFactory = (): ActivationFn =>
  (toState: State): boolean | Promise<boolean> => {
    const { error, pageType } = toState as RouteState;
    if (error || pageType !== PageType.private) {
      return true;
    }
    return check(toState as RouteState);
  };
