import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { RouteState, companyIdParameterName, companyIdStorageName, redirectParameterName } from '@router';

export const companyIdParameterHandler: ActivationFnFactory = (): ActivationFn => (toState: State): boolean => {
  const { name, pageType } = toState as RouteState;
  const isLogin = name === 'login';
  if (pageType === 'public' && !isLogin || (toState as RouteState)[redirectParameterName]) {
    return true;
  }
  const companyIdParameter = toState.params[companyIdParameterName];
  if (companyIdParameter) {
    sessionStorage.setItem(companyIdStorageName, companyIdParameter);
  } else if (isLogin) {
    sessionStorage.removeItem(companyIdStorageName);
  }
  return true;
};
