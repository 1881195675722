import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { RouteState } from '@router';

export const scrollUpUtility: ActivationFnFactory = (): ActivationFn => (toState: State): boolean => {
  const { isRouted } = toState as RouteState;
  if (!isRouted) {
    return true;
  }
  const element = document.querySelector('.p360-scrollbar-content');
  if (element) {
    element.scrollTop = 0;
  }
  return true;
};
