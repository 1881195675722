import { BehaviorSubject, Subject } from 'rxjs';
import { useEffect, useState } from 'react';
import { takeUntil } from 'rxjs/operators';
import { useMapper } from '@hooks';

export type UseBehaviorSubjectSubscriptionOptions<T, R> = {
  observable$: BehaviorSubject<T>;
  mapper?: (value: T) => R;
  unsubscribe$: Subject<void>;
};
export const useBehaviorSubjectSubscription = <T, R = T>(options: UseBehaviorSubjectSubscriptionOptions<T, R>): R => {
  const {
    observable$,
    mapper,
    unsubscribe$
  } = options;
  const getValue = useMapper(mapper);
  const [ data, setData ] = useState<R>(getValue(observable$.value));
  useEffect(() => {
    observable$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value: T) => setData(getValue(value)));
  }, []);
  return data;
};
