import { Api } from '@services/api/abstract';
import { Observable } from 'rxjs';
import { NewsItem } from '@services';

const apiUrl = '/api/survey/news';

class NewsApiService extends Api {
  public getNewsList(): Observable<NewsItem[]> {
    return this.get<NewsItem[]>({ url: 'public' });
  }
}

export const newsApiService = new NewsApiService(apiUrl);
