import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { isHasOwnedProducts$, getIsHasOwnedProducts } = productService;
export const useIsHasOwnedProducts = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: isHasOwnedProducts$,
  initialValue: getIsHasOwnedProducts(),
  unsubscribe$
});
