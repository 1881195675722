import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { RouteState } from '@router';
import { userService } from '@services';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const logoutMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const routeName = toState?.name;
  if (routeName !== 'login') {
    return true;
  }
  return new Promise((resolve: (value: boolean) => void) => {
    return userService
      .logout()
      .pipe(catchError((): Observable<null> => of(null)))
      .subscribe(() => {
        (toState as RouteState).isLoggedIn = false;
        resolve(true);
      });
  });
};
