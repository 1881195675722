import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { NewsItem, newsService } from '@services';

export const newsResolver = (): Observable<NewsItem[]> => newsService.getNewsList()
  .pipe(
    take(1),
    catchError(() => of(null)),
    map((newsList: null | NewsItem[]) => newsList === null
      ? []
      : newsList
    )
  );
