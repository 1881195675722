import { PageType, bothRouteNameList, publicRouteNameList } from '@router';

export const getPageType = (routeName: string): PageType => {
  if (bothRouteNameList.includes(routeName)) {
    return PageType.both;
  } else if (publicRouteNameList.includes(routeName)) {
    return PageType.public;
  }
  return PageType.private;
};
