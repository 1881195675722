import { Observable, of } from 'rxjs';
import { ResolverData } from '@router';

export const getParameterResolver = (parameterName: string, transform?: (value: string) => string) => (data: ResolverData): Observable<null | string> => {
  const parameter = data.parameter[parameterName];
  return parameter
    ? typeof transform !== 'undefined'
      ? of(transform(parameter))
      : of(parameter)
    : of(null);
};
