import React, { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { i18n } from '@services';
import { Message, MultipleFieldErrors } from 'react-hook-form';

import styles from './ErrorFactory.styl';

type ErrorRenderer = (data: { message: Message; messages?: MultipleFieldErrors; }) => ReactNode;
type ErrorRendererFactory = (t: TFunction, fieldName: string, params?: object) => ErrorRenderer;

const ErrorFactory: ErrorRendererFactory = (t, fieldName, params = {}) => ({ message }) => {
  const text = i18n.exists(`form:errors.${message}`)
    ? t(`form:errors.${message}`, {fieldName, ...params})
    : message;
  return (
    <span className={styles.error}>{text}</span>
  );
};

export default ErrorFactory;
