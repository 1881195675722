import { Route } from 'router5';
import { routeList } from '../../configuration/routeList';
import { RouteOptions, RouterDependency, RouterMetaData } from '../../interfaces';

export const routerDependencies: Record<string, RouterDependency> = {};
export const routerMeta: Record<string, RouterMetaData> = {};
export const setRoute = (options: RouteOptions): Route => {
  const {
    name,
    path: pathString,
    params,
    component,
    lazy = true,
    dynamic = false,
    resolve,
    canActivate,
    namespace = name,
    titleOptions,
    forwardTo
  } = options;
  const path = pathString
    ? pathString
    : params && params.path
      ? params.path
      : routeList[name];
  routerMeta[name] = {
    namespace,
    titleOptions,
    pageTitle: '',
    permission: {}
  };
  routerDependencies[options.name] = {
    component,
    lazy,
    dynamic,
    resolve: { ...resolve }
  };
  return { name, path, canActivate, forwardTo };
};
