import { ActivationFn, ActivationFnFactory, NavigationOptions, State } from 'router5';
import {
  defaultRoute,
  getRedirectParameters,
  redirect,
  redirectParameterName,
  RouteState
} from '@router';

export const defaultRouteMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State, fromState: State): boolean | Promise<boolean> => {
  const { name } = toState;
  if (name !== 'default') {
    return true;
  }
  const route = defaultRoute();
  const fromName = fromState?.name || null;

  const parameters = getRedirectParameters({
    toState: toState as RouteState,
    parseQuery: false,
    parameters: {
      [redirectParameterName]: true,
      openModal: toState.params.openModal
    }
  });
  const options: NavigationOptions = {
    reload: fromName === route
  };
  redirect({ route, parameters, options });
  return false;
};
