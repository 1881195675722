import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  backButtonRouteList,
  clearLastPageStorage,
  getLastPageStorageValue,
  isApplicationProduct,
  isExternalRouteName,
  isPublicRouteName,
  productParameterName,
  RouteListType,
  setLastPageStorageValue
} from '@router';

export const lastPageMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State, fromState: State): boolean | Promise<boolean> => {
  const routeName = fromState?.name;
  if (routeName === 'consent') {
    return true;
  }
  // TODO what is expected behaviour of back button for case when user navigate between organization and profile?
  // TODO what is expected behaviour of back button for case when user is redirected from consent page to profile (on updateAnalyticsAllowed error)?

  const { name: key, params } = toState;
  const productParameter = params[productParameterName];
  const isBackButtonRoute = backButtonRouteList.includes(key);
  if (!isBackButtonRoute) {
    clearLastPageStorage();
    return true;
  }

  if (!routeName && isApplicationProduct(productParameter)) {
    setLastPageStorageValue(key, productParameter);
  }

  if (routeName) {
    const isPublic = isPublicRouteName({ routeName });
    const isExternal = isExternalRouteName(routeName as RouteListType);
    const lastPageValue = getLastPageStorageValue(key);
    if (!isPublic && !isExternal && !lastPageValue) {
      setLastPageStorageValue(key, routeName as RouteListType);
    }
  }
  return true;
};
