import { redirect, redirectParameterName } from '@router';

interface IDefaultRedirectOptions {
  useRedirect?: boolean;
  parameters?: object;
}

interface IDefaultRedirectParameters {
  useRedirect: boolean;
  parameters: object;
}

const defaultParameters: IDefaultRedirectParameters = {
  useRedirect: true,
  parameters: {}
};

export const defaultRedirect = (options: IDefaultRedirectOptions = {}) => {
  const { useRedirect, parameters } = { ...defaultParameters, ...options };
  useRedirect
    ? redirect({
      route: 'default',
      parameters: { ...parameters, [redirectParameterName]: false }
    })
    : redirect({ route: 'default' });
};
