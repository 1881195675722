import { Observable } from 'rxjs';
import { Collection, TranslationInfo } from '@interfaces';
import {
  AvailableUserListParameters,
  CourseInvitationData,
  User,
} from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey/team';

class TeamApiService extends Api {

  public getAvailableUserList(parameters: Partial<AvailableUserListParameters>): Observable<Collection<User>> {
    const { context = 'all', ...query } = parameters;
    return this.get<Collection<User>>({
      url: `${context}/available-users`,
      parameters: {...query}
    });
  }

  public checkUserInvitationToken(token: string): Observable<TranslationInfo> {
    return this.get<TranslationInfo>({
      url: 'academy/invitation',
      parameters: { token }
    });
  }

  public inviteUserByInvitationToken(parameters: CourseInvitationData): Observable<number> {
    return this.post<number>({
      url: 'academy/invitation',
      parameters,
      suppressDefaultErrorHandler: true,
      returnType: 'status'
    });
  }
}

export const teamApiService = new TeamApiService(apiUrl);
