import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './WhistleNoteModalContent.styl';

const WhistleNoteModalContent: FC = (): null | ReactElement => {
  const { t } = useTranslation('products');
  const text = t('translations.whistleModalText');
  return <p className={ styles.text }>{ text }</p>;
};

export default memo(WhistleNoteModalContent);
