import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { offeredProducts$, getOfferedProducts } = productService;
export const useOfferedProducts = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: offeredProducts$,
  initialValue: getOfferedProducts(),
  unsubscribe$
});
