import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { take } from 'rxjs/operators';
import {
  PageType,
  RouteState,
  ComponentTypeDictionary,
  RouteListType,
  getRouteListTypeByRouteName,
  ignorePermissionsRouteNameList,
  isUndefined,
  routerDependencies
} from '@router';
import { PagePermissionData, permissionService } from '@services';

const check = (toState: RouteState): Promise<boolean> => new Promise((resolve: (value: boolean) => void) => permissionService
  .pagePermission$
  .pipe(take(1))
  .subscribe((pagePermissionData: null | PagePermissionData): void => {
    if (pagePermissionData === null) {
      return resolve(false);
    }
    const { dynamic, component } = routerDependencies[toState.name];
    if (dynamic) {
      const routeList = Object.keys(component as ComponentTypeDictionary) as RouteListType[];
      const pagePermissionList: Array<undefined | boolean> = routeList.map(
        (routeListType: RouteListType) => pagePermissionData[routeListType]
      );
      if (pagePermissionList.every((pagePermission: undefined | boolean) => isUndefined(pagePermission))) {
        toState.error = 404;
      } else if (pagePermissionList.every((pagePermission: undefined | boolean) => !pagePermission)) {
        toState.error = 403;
      }
    } else {
      const exceptionsList: RouteListType[] = [ 'companies', 'products' ];
      const routeName = getRouteListTypeByRouteName(toState.name);
      if (exceptionsList.includes(routeName)) {
        return resolve(true);
      }
      const pagePermission: undefined | boolean = pagePermissionData[routeName as RouteListType];
      if (isUndefined(pagePermission)) {
        toState.error = 404;
      } else if (!pagePermission) {
        toState.error = 403;
      }
    }
    return resolve(true);
  }));

export const permissionGuard: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { name, error, pageType } = toState as RouteState;
  if (error || pageType !== PageType.private || ignorePermissionsRouteNameList.includes(name)) {
    return true;
  }
  return check(toState as RouteState);
};
