import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { isHasOfferedProducts$, getIsHasOfferedProducts } = productService;
export const useIsHasOfferedProducts = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: isHasOfferedProducts$,
  initialValue: getIsHasOfferedProducts(),
  unsubscribe$
});
