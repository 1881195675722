import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  RouteState,
  PageType,
  getEmailParameterValue,
  getRedirectParameters,
  errorParameterName
} from '@router';
import { userService } from '@services';

const check = (toState: RouteState): boolean | Promise<boolean> => {
  const email = getEmailParameterValue(toState);
  if (!email || userService.user$.value?.email === email) {
    return true;
  }
  const name = 'login';
  const params = getRedirectParameters({
    toState,
    parameters: {
      [errorParameterName]: 401
    }
  });
  return Promise.reject({ redirect: { name, params } });
};

export const emailGuard: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { error, isLoggedIn, pageType } = toState as RouteState;
  if (error || !isLoggedIn || pageType === PageType.public) {
    return true;
  }
  return check(toState as RouteState);
};
