import $ from 'jquery';
import { DataFormat, GroupedDataFormat, IdTextPair, LoadingData } from 'select2';

type ItemType = DataFormat | GroupedDataFormat | LoadingData | IdTextPair;

const countryTemplateSelection = (state: ItemType): string | JQuery => {
  const $result = $('<span />');
  if (!state.id || state.id === 'false') {
    return $result.text(state.text);
  }
  const countryCode = (state.id as string).toLowerCase();
  const $flag = $(`<span class="country-flag flag-icon flag-icon-${ countryCode }" />`);
  const $text = $('<span class="country-name"/>');
  $text.text(state.text);
  $result.append($flag);
  $result.append($text);
  return $result;
};

export default countryTemplateSelection;
