import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { onboardingAllowedRouteList, PageType, RouteState } from '@router';
import { companyService, permissionService } from '@services';

export const onboardingGuard: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { name, params, pageType } = toState as RouteState;

  const isCompanySelected = companyService.getIsCompanySelected();
  if (pageType === PageType.public || !isCompanySelected || name === 'consent') {
    return true;
  }
  const isHasOnboardingPermission = permissionService.isPageAvailable('onboarding');
  if (name === 'onboarding' && !isHasOnboardingPermission) {
    return Promise.reject({ redirect: { name: 'default', params } });
  }
  if (name !== 'onboarding' && isHasOnboardingPermission && !onboardingAllowedRouteList.includes(name)) {
    return Promise.reject({ redirect: { name: 'onboarding', params } });
  }
  return true;
};
