import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { isSingleProduct$, getIsSingleProduct } = productService;
export const useIsSingleProduct = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: isSingleProduct$,
  initialValue: getIsSingleProduct(),
  unsubscribe$
});
