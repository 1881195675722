import { Observable } from 'rxjs';
import { Api } from '@services/api/abstract';
import { StreamMessage } from '@services/data/stream';

const apiUrl = '/api/streaming/sse';

class StreamApiService extends Api {
  public getStream(): Observable<StreamMessage> {
    return this.stream();
  }
  public healthCheck(): Observable<number> {
    return this.head({
      returnType: 'status',
      suppressDefaultErrorHandler: true,
      omitAuthorizationToken: true
    });
  }
}

export const streamApiService = new StreamApiService(apiUrl);
