import { MutableRefObject, useEffect, useRef } from 'react';

type RefType<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

const useCombinedRefs = <T>(...refs: Array<RefType<T>>): MutableRefObject<null | T> => {
  const targetRef: MutableRefObject<null | T> = useRef<T>(null);

  useEffect(
    () => {
      refs
        .forEach(
          (ref: RefType<T>) => {
            if (!ref || typeof targetRef.current === 'undefined') {
              return;
            }
            if (typeof ref === 'function') {
              ref(targetRef.current);
            } else {
              ref.current = targetRef.current;
            }
          });
    },
    [refs]
  );

  return targetRef;
};

export default useCombinedRefs;
