import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { companyService } from '@services';

const { isMultitenancy$, getIsMultitenancy } = companyService;
export const useIsMultitenancy = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: isMultitenancy$,
  initialValue: getIsMultitenancy(),
  unsubscribe$
});
