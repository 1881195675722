import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { productsCount$, getProductsCount } = productService;
export const useProductsCount = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: productsCount$,
  initialValue: getProductsCount(),
  unsubscribe$
});
