import { Observable } from 'rxjs';
import { TaskTypeType } from '@interfaces';
import {
  SurveyData,
  SurveyParams,
  SurveyRequestData,
  SurveyResult
} from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey';

class SurveyApiService extends Api {
  public getSurveyData(
    id: string,
    taskType: TaskTypeType,
    parameters: Partial<SurveyParams>
  ): Observable<SurveyData> {
    return this.get<SurveyData>({
      url: `${taskType}/${id}`,
      parameters
    });
  }

  public getSurveyByName(name: string): Observable<SurveyData> {
    return this.get<SurveyData>({ url: name });
  }

  public saveSurveyResult<T>(
    id: string,
    parameters: Record<string, string>,
    body: SurveyRequestData<T>
  ): Observable<SurveyResult> {
    return this.post({
      url: `result/${id}`,
      parameters,
      body
    });
  }

  public updateSurveyResult<T>(
    id: string,
    parameters: Record<string, string>,
    body: SurveyRequestData<T>
  ): Observable<SurveyResult> {
    return this.put({
      url: `result/${id}`,
      parameters,
      body
    });
  }
}
export const surveyApiService = new SurveyApiService(apiUrl);
