import { Observable, of } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';
import { SystemsService } from '@services';
import { currentTimeApiService } from '@services/api';

let currentTime: null | string = null;

const getCurrentTime = (): Observable<string> => currentTime !== null
  ? of(currentTime)
  : currentTimeApiService.getCurrentTime()
    .pipe(
      take(1),
      tap((value: string) => {
        currentTime = value;
      }),
      share()
    );

export const systemsService: SystemsService = {
  getCurrentTime
};
