import type { FullRouteListType } from '@router';
import {
  companyService,
  permissionService,
  productService,
  userService
} from '@services';

export const defaultRoute = (): FullRouteListType => {
  const isLoggedIn = userService.isLoggedIn$.value;
  if (!isLoggedIn) {
    return 'login';
  }
  if (!companyService.getIsCompanySelected()) {
    return 'companies';
  }
  const hasPermission = (page: FullRouteListType) => permissionService.isPageAvailable(page);
  if (hasPermission('onboarding')) {
    return 'onboarding';
  }
  const singleInternalProductName = productService.getSingleInternalProductName();
  if (singleInternalProductName) {
    if (singleInternalProductName === 'ACADEMY') {
      if (hasPermission('courseManagement')) {
        return 'courseManagement';
      }
      if (hasPermission('myCourses')) {
        return 'myCourses';
      }
      if (hasPermission('dashboard')) {
        return 'dashboard';
      }
    }
    if (singleInternalProductName === 'PRIVACY') {
      if (hasPermission('dashboard')) {
        return 'dashboard';
      }
      if (hasPermission('risk')) {
        return 'risk';
      }
      if (hasPermission('dataBreach')) {
        return 'dataBreach';
      }
    }
  }
  return 'products';
};
