import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { companyService } from '@services';
import { isPublicRouteName } from '@router';

export const selectCompanyMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State, fromState: State): boolean | Promise<boolean> => {
  const { name, params } = toState;
  if (!fromState?.name && !isPublicRouteName({ routeName: name }) && !['default', 'companies'].includes(name)) {
    companyService.setIsCompanySelected(true);
  }
  if (name === 'login') {
    companyService.setIsCompanySelected(false);
    return true;
  }
  if (name !== 'companies') {
    return true;
  }
  if (!companyService.getIsMultitenancy()) {
    companyService.setIsCompanySelected(true);
    return Promise.reject({ redirect: { name: 'default', params } });
  }
  // TODO If company is already selected trough `companyIdParameterName` query parameter - redirects user to `default` route ???
  return true;
}
