export interface UserFormat {
  email: string;
  firstName?: string;
  lastName?: string;
}

export const userFormat = (user: UserFormat) => {
  const {
    firstName = '',
    lastName = '',
    email
  } = user;
  return `${ firstName } ${ lastName } <${ email }>`.trim();
};
