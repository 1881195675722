import createRouter, { Router, State } from 'router5';
import { Params } from 'router5/dist/types/base';
import browserPlugin from 'router5-plugin-browser';
// import loggerPlugin from 'router5-plugin-logger';
import { Subject } from 'rxjs';
import {
  productRedirect,
  redirectParameterHandler,
  authorizationGuard,
  checkUserMiddleware,
  companyIdParameterHandler,
  companyIdStorageHandler,
  consentGuard,
  closeModalUtility,
  componentMiddleware,
  defaultRoute,
  defaultRouteMiddleware,
  emailGuard,
  externalRedirectParameterHandler,
  externalRedirectStorageHandler,
  isExternalRouteName,
  lastPageMiddleware,
  linkInterceptor,
  loadingIndicatorPlugin,
  logoutMiddleware,
  metadataMiddleware,
  onboardingGuard,
  scrollUpUtility,
  pageTitleMiddleware,
  permissionGuard,
  redirectStorageHandler,
  RedirectOptions,
  RouteState,
  RouteListType,
  resolveMiddleware,
  selectCompanyMiddleware,
  selectProductMiddleware,
  setDefaultMiddleware,
  showErrorUtility,
  showNotificationUtility
} from '@router';

const routerOptions = (): any => ({
  allowNotFound: true,
  autoCleanUp: true
});

let routerInstance: Router;

const linkInterceptorCallback = (error: any) => {
  if (error) {
    console.error(error);
  }
};
const linkInterceptorOptions = (_routeName: string, _routeParams: object): object => {
  return {};
};

export const setRouter = (routes: any[]): Router => {
  if (routerInstance) {
    throw Error('rootRouter init only once');
  }
  routerInstance = createRouter(routes, routerOptions());
  routerInstance.useMiddleware(
    checkUserMiddleware,
    logoutMiddleware,
    closeModalUtility,
    showErrorUtility,
    setDefaultMiddleware,
    authorizationGuard,
    emailGuard,
    companyIdParameterHandler,
    redirectParameterHandler,
    externalRedirectParameterHandler,
    externalRedirectStorageHandler,
    companyIdStorageHandler,
    consentGuard,
    selectCompanyMiddleware,
    onboardingGuard,
    selectProductMiddleware,
    lastPageMiddleware,
    showNotificationUtility,
    redirectStorageHandler,
    defaultRouteMiddleware,
    permissionGuard,
    componentMiddleware,
    metadataMiddleware,
    resolveMiddleware,
    pageTitleMiddleware,
    scrollUpUtility
  );
  routerInstance.usePlugin(
    // loggerPlugin,
    browserPlugin({
      useHash: false,
      forceDeactivate: false
    }),
    loadingIndicatorPlugin,
    linkInterceptor(linkInterceptorOptions, linkInterceptorCallback)
  );
  return routerInstance;
};

export const router = (): Router => {
  if (!routerInstance) {
    throw Error('rootRouter was not init');
  }
  return routerInstance;
};

export const redirect = (options: RedirectOptions = {}): void => {
  const {
    route = defaultRoute(),
    parameters = {},
    options: navigationOptions = {}
  } = options;
  if (isExternalRouteName(route)) {
    productRedirect({ route, parameters });
    return;
  }
  routerInstance.navigate(route, parameters, navigationOptions);
};

export const navigate = (href: string) => {
  const { pathname, search } = new URL(href);
  const data: null | State = routerInstance.matchPath(pathname + search);
  if (data) {
    routerInstance.clearCanDeactivate(routerInstance.getState().name);
    const { name, params } = data;
    routerInstance.navigate(name, params);
  }
};

export const routerStateReplaced$ = new Subject<RouteState>();

export const replaceHistoryState = (
  name: RouteListType,
  params: Params = {}
): void => {
  routerInstance.replaceHistoryState(name, params);
  routerStateReplaced$.next(routerInstance.lastKnownState as RouteState);
};

export const matchUrl = (url: string) => routerInstance.matchUrl(url);
