import { Observable } from 'rxjs';
import {
  CompanyBusiness,
  CompanyData,
  CompanyManagementData,
  CompanyManagement,
  AssignCompanyManagementData,
  EditInviteCompanyManagementData
} from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey/company';

class OrganizationApiService extends Api {
  public getCompanyBasicData(): Observable<CompanyData> {
    return this.get<CompanyData>({ url: 'basic-data' });
  }

  public updateCompanyBasicData(body: CompanyData): Observable<CompanyData> {
    return this.put<CompanyData>({
      url: 'basic-data',
      body
    });
  }

  public getCompanyManagement(): Observable<CompanyManagement[]> {
    return this.get<CompanyManagement[]>({ url: 'management' });
  }

  public assignManagementPerson(data: AssignCompanyManagementData): Observable<CompanyManagement> {
    const { userId, responsibilityType } = data;
    return this.post({
      url: `management/${ userId }`,
      parameters: { responsibilityType }
    });
  }

  public updateManagementPerson(
    id: number,
    body: AssignCompanyManagementData | EditInviteCompanyManagementData
  ): Observable<CompanyManagement> {
    return this.put({
      url: `management/${ id }`,
      body
    });
  }

  public inviteManagementPerson(data: CompanyManagementData): Observable<CompanyManagement> {
    const { responsibilityType, ...body } = data;
    return this.post({
      url: 'management',
      parameters: { responsibilityType },
      body,
      suppressDefaultErrorHandler: true
    });
  }

  public unAssignManagementPerson(itemId: number): Observable<null> {
    return this.delete<null>({ url: `management/${ itemId }` });
  }

  public getCompanyBusinessDetails(): Observable<CompanyBusiness> {
    return this.get({ url: 'business-details' });
  }

  public updateCompanyBusinessDetails(body: CompanyBusiness): Observable<CompanyBusiness> {
    return this.put({
      url: 'business-details',
      body
    });
  }
}

export const organizationApiService = new OrganizationApiService(apiUrl);
