import $ from 'jquery';

const markMatch = (text: string, term?: string): string | JQuery => {
  if (!term || typeof term === 'object') {
    return text;
  }
  const match = text.toUpperCase().indexOf(term.toUpperCase());
  const $result = $('<span />');
  if (match < 0) {
    return $result.text(text);
  }
  $result.text(text.substring(0, match));
  const $match = $('<span class="select2-rendered__match" />');
  $match.text(text.substring(match, match + term.length));
  $result.append($match);
  $result.append(text.substring(match + term.length));
  return $result;
};

export default markMatch;
