import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  getQueryParameterValueList,
  notificationParameterName,
  NotificationType,
  showNotification
} from '@router';
import { i18n } from '@services';

let listener: undefined | (() => void);
let route: string;

const unsubscribe = (): void => {
  i18n.off('loaded', listener);
  listener = undefined;
};

export const showNotificationUtility: ActivationFnFactory = (): ActivationFn => (toState: State): boolean => {
  const { name, params } = toState;
  if (route && route !== name) {
    if (typeof listener !== 'undefined') {
      unsubscribe()
    }
  }
  if (name === 'login') {
    return true;
  }

  const notificationKeyList = getQueryParameterValueList(params, notificationParameterName);
  if (!notificationKeyList.length) {
    return true;
  }

  const type: NotificationType = 'warn';
  const options = {
    type,
    textTranslationPrefix: 'sse',
    textTranslationKeyList: notificationKeyList
  };
  if (!showNotification(options)) {
    route = name;
    listener = () => {
      if (showNotification(options)) {
        unsubscribe()
      }
    };
    i18n.on('loaded', listener);
  }

  // delete params[notificationParameterName];
  return true;
};
