import { Observable, of } from 'rxjs';
import { emailValidation, getEmailValue, ResolverData } from '@router';
import { switchMap } from 'rxjs/operators';

export const emailResolver = <T>(
  onValid: (email: string) => Observable<T>
) => (data: ResolverData): Observable<null | T> => {
  const emailValue = getEmailValue(data);
  if (!emailValue) {
    return of(null);
  }
  return emailValidation(emailValue)
    .pipe(switchMap((isValid: boolean) => isValid
      ? onValid(emailValue)
      : of(null)
    ));
};
