import { Observable, Subject } from 'rxjs';
import { useEffect, useState } from 'react';
import { takeUntil } from 'rxjs/operators';
import { useMapper } from '@hooks';

export type UseObservableSubscriptionOptions<T, R> = {
  initialValue: R;
  observable$: Observable<T>;
  mapper?: (value: T) => R;
  unsubscribe$: Subject<void>;
};
export type UseObservableSubscription = <T, R = T>(options: UseObservableSubscriptionOptions<T, R>) => R;

export const useObservableSubscription: UseObservableSubscription = <T, R = T>(options: UseObservableSubscriptionOptions<T, R>): R => {
  const {
    observable$,
    initialValue,
    mapper,
    unsubscribe$
  } = options;
  const getValue = useMapper(mapper);
  const [ data, setData ] = useState<R>(initialValue);
  useEffect(() => {
    observable$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value: T) => setData(getValue(value)));
  }, []);
  return data;
}
