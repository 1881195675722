import { staticRouteNameList } from './staticRouteNameList';

export const publicRouteNameList: string[] = [
  'accountActivation',
  'courseInvitation',

  'login',
  'sso',
  'forgot',
  'reset',

  ...staticRouteNameList,
];
