import { Observable } from 'rxjs';
import { TaskTypeDecode } from '@interfaces';
import {
  COMPLETED,
  SurveyService,
  RUNNING,
  SurveyData,
  SurveyParams,
  SurveyResult,
  SurveyRequestData
} from '@services';
import { surveyApiService } from '@services/api';

const surveyData = <T>(pageNo: number, status: COMPLETED | RUNNING, data: T): SurveyRequestData<T> => {
  return { lastActivePage: pageNo, status, result: data };
};

const surveyPostDecodeParams = (params: any) => {
  if (params.type && TaskTypeDecode[params.type as keyof typeof TaskTypeDecode]) {
    params.type = TaskTypeDecode[params.type as keyof typeof TaskTypeDecode];
  }
};

const getSurveyData = (params: SurveyParams): Observable<SurveyData> => {
  const { id, type } = params;
  const taskType = TaskTypeDecode[type];
  const parameters: Partial<SurveyParams> = {};
  if (params.showLastResult) {
    parameters.showLastResult = params.showLastResult;
  }
  if (params.forceReadonly) {
    parameters.forceReadonly = params.forceReadonly;
  }
  return surveyApiService.getSurveyData(id, taskType, parameters);
};

const saveSurveyResult = <T>(
  id: string,
  pageNo: number,
  status: COMPLETED | RUNNING,
  data: T,
  parameters: Record<string, string>
): Observable<SurveyResult> => {
  surveyPostDecodeParams(parameters);
  return surveyApiService.updateSurveyResult(id, parameters, surveyData(pageNo, status, data));
};

const updateSurveyResult = <T>(
  id: string,
  pageNo: number,
  status: COMPLETED | RUNNING,
  data: T,
  parameters: Record<string, string>
): Observable<SurveyResult> => {
  surveyPostDecodeParams(parameters);
  return surveyApiService.updateSurveyResult(id, parameters, surveyData(pageNo, status, data));
};

export const surveyService: SurveyService = {
  getSurveyData,
  getSurveyByName: surveyApiService.getSurveyByName.bind(surveyApiService),
  saveSurveyResult,
  updateSurveyResult
};
