import { publicRouteNameList } from '../../configuration';
import { isInRouteNameList } from './isInRouteNameList';

export interface IsPublicRouteNameOptions {
  routeName?: null | string;
  include?: string | string[];
  exclude?: string | string[];
}

export const isPublicRouteName = (data: IsPublicRouteNameOptions): boolean => isInRouteNameList({
  ...data,
  routeNameList: publicRouteNameList
});
