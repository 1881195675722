import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  externalRedirectStorageName,
  RouteState
} from '@router';

export const externalRedirectStorageHandler: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { error, isLoggedIn } = toState as RouteState;
  if (error || !isLoggedIn) {
    return true;
  }
  const externalRedirectStored: null | string = sessionStorage.getItem(externalRedirectStorageName);
  if (externalRedirectStored) {
    sessionStorage.removeItem(externalRedirectStorageName);
    window.location.href = externalRedirectStored;
    return false;
  }
  return true;
};
