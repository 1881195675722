import { IComponentPromiseFactory } from '@router';

export const routeComponents: Record<string, IComponentPromiseFactory<any>> = {
  Error: () => import(/* webpackChunkName: "Error" */ '@pages/Error/Error'),

  Login: () => import(/* webpackPrefetch: true, webpackChunkName: "Login" */ '@pages/Login/LoginPage'),
  Forgot: () => import(/* webpackChunkName: "Forgot" */ '@pages/Forgot/Forgot'),
  Reset: () => import(/* webpackChunkName: "Reset" */ '@pages/Reset/Reset'),
  AccountActivation: () => import(/* webpackChunkName: "AccountActivation" */ '@pages/AccountActivation/AccountActivation'),
  CourseInvitation: () => import(/* webpackChunkName: "CourseInvitation" */ '@pages/CourseInvitation/CourseInvitation'),

  CompanySwitcher: () => import(/* webpackChunkName: "CompanySwitcher" */ '@pages/CompanySwitcher/CompanySwitcherPage'),
  ProductSwitcher: () => import(/* webpackChunkName: "ProductSwitcher" */ '@pages/ProductSwitcher/ProductSwitcherPage'),

  Profile: () => import(/* webpackChunkName: "Profile" */ '@pages/Profile/ProfilePage'),

  Onboarding: () => import(/* webpackChunkName: "Onboarding" */ '@pages/Onboarding/Onboarding'),

  Organization: () => import(/* webpackChunkName: "Organization" */ '@pages/Organization/OrganizationPage'),

  FooterDocument: () => import(/* webpackChunkName: "FooterDocument" */ '@pages/FooterDocument/FooterDocument'),
  ConsentPage: () => import(/* webpackChunkName: "ConsentPage" */ '@layout/ConsentLayout/ConsentLayout'),

};
