import { companyService, userService } from '@services';

export interface GetKeyOptions {
  useUserId?: boolean;
  useCompanyId?: boolean;
  delimiter?: string;
}

export const getKey = (key: string, options: GetKeyOptions = {}): string => {
  const {
    useUserId = true,
    useCompanyId = true,
    delimiter = '-'
  } = options;

  return [
    useUserId && userService.user$.value?.id,
    useCompanyId && companyService.getCurrentCompanyId(),
    key
  ]
    .filter(Boolean)
    .join(delimiter);
}
