import { Params } from 'router5/dist/types/base';

export const getQueryParameterValueList = (params: Params, parameterName: string): Array<number | string> => {
  const queryParameterValue = params[parameterName];
  return queryParameterValue
    ? Array.isArray(queryParameterValue)
      ? queryParameterValue
      : [ queryParameterValue ]
    : [];
};
