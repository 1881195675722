import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  errorParameterName,
  getQueryParameterValueList,
  showNotification,
  NotificationType
} from '@router';
import { i18n } from '@services';

let listener: undefined | (() => void);

const unsubscribe = (): void => {
  i18n.off('loaded', listener);
  listener = undefined;
};

export const showErrorUtility: ActivationFnFactory = (): ActivationFn => (toState: State): boolean => {
  const { name, params } = toState;
  if (name !== 'login') {
    if (typeof listener !== 'undefined') {
      unsubscribe()
    }
    return true;
  }

  const errorKeyList = getQueryParameterValueList(params, errorParameterName);
  if (!errorKeyList.length) {
    return true;
  }

  const type: NotificationType = 'error';
  const options = {
    type,
    textTranslationPrefix: 'error',
    textTranslationKeyList: errorKeyList
  };
  if (!showNotification(options)) {
    listener = () => {
      if (showNotification(options)) {
        unsubscribe()
      }
    };
    i18n.on('loaded', listener);
  }

  // delete params[errorParameterName];
  return true;
};
