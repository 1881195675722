import { Observable, of } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';
import { Api } from '@services/api';

const url = '/api/survey/system';

class CurrentTimeApiService extends Api {

  private currentTime: null | string = null;

  public getCurrentTime(): Observable<string> {
    if (this.currentTime !== null) {
      return of(this.currentTime);
    } else {
      return this.get<string>({
        url: 'current-time',
        responseType: 'text',
        suppressDefaultErrorHandler: true
      })
        .pipe(
          take(1),
          tap((currentTime: string) => {
            this.currentTime = currentTime;
          }),
          share()
        );
    }
  }
}

export const currentTimeApiService = new CurrentTimeApiService(url);
