import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { singleProductName$, getSingleProductName } = productService;
export const useGetSingleProduct = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: singleProductName$,
  initialValue: getSingleProductName(),
  unsubscribe$
});
