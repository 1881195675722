import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { Collection, ITableParameters } from '@interfaces';
import { Company, CompanyService, permissionService, productService, streamService, userService } from '@services';
import { userApiService } from '@services/api';

const isCompanySelectedKey = 'isCompanySelected';
const isCompanySelectedValue = sessionStorage.getItem(isCompanySelectedKey);
const isCompanySelected = isCompanySelectedValue === null
  ? false
  : JSON.parse(isCompanySelectedValue);
const isCompanySelected$ = new BehaviorSubject<boolean>(isCompanySelected);
const setIsCompanySelected = (isCompanySelected: boolean): void => {
  isCompanySelected$.next(isCompanySelected);
};
const getIsCompanySelected = (): boolean => isCompanySelected$.value;

const isInitialized$ = new BehaviorSubject<boolean>(false);
const company$ = new BehaviorSubject<null | Company>(null);
const companyId$ = new BehaviorSubject<null | number>(null);
const getCurrentCompanyId = (): null | number => companyId$.value;

const setCompany = (company: null | Company): null | Company => {
  company$.next(company);
  companyId$.next(company ? company.id : null);
  isInitialized$.value
    ? streamService.reconnect()
    : streamService.connect();
  isInitialized$.next(true);
  return company;
};
const getCurrentCompany = (): null | Company => company$.value;
const resetCompany = (): void => {
  company$.next(null);
  companyId$.next(null);
};

const noCompaniesAssignedMapper = (company: null | Company): boolean => company === null;
const getNoCompaniesAssigned = (): boolean => noCompaniesAssignedMapper(company$.value);
const noCompaniesAssigned$: Observable<boolean> = company$.pipe(map(noCompaniesAssignedMapper));

const availableCompaniesList$ = new BehaviorSubject<Company[]>([]);
const getAvailableCompanyCollection = (
  parameters: Partial<ITableParameters<Company>> = {},
  suppressDefaultErrorHandler?: boolean | number[]
): Observable<Collection<Company>> => userApiService.getAvailableCompanyCollection(parameters, suppressDefaultErrorHandler);
const updateAvailableCompaniesList = (): Observable<void> => getAvailableCompanyCollection()
  .pipe(
    take(1),
    map((companyCollection: Collection<Company>) => availableCompaniesList$.next(companyCollection.elements)));

const isMultitenancyMapper = (companiesList: Company[]): boolean => companiesList.length > 1;
const getIsMultitenancy = (): boolean => isMultitenancyMapper(availableCompaniesList$.value);
const isMultitenancy$ = availableCompaniesList$.pipe(map(isMultitenancyMapper));

const setCurrentCompany = (companyId: number): Observable<Company> => userApiService
  .setCurrentCompany(companyId);
const getAvailableCompanyById = (companyId: number): Observable<null | Company> => availableCompaniesList$
  .pipe(map((companiesList: Company[]) => companiesList
    .find((company: Company): boolean => company.id === companyId) || null));

const selectCurrentCompany = (companyId: null | number, sendCompanyRequest: boolean = true, setNull: boolean = true): Observable<null | Company> => {
  if (companyId === null) {
    return of(null);
  }
  const currentCompanyId = companyService.getCurrentCompanyId();
  if (currentCompanyId === companyId) {
    return of(company$.value);
  }
  companyId$.next(companyId);
  const request = sendCompanyRequest
    ? setCurrentCompany(companyId)
    : getAvailableCompanyById(companyId);
  return request
    .pipe(
      catchError(() => of(null)),
      mergeMap((company: null | Company) => {
        if (!!company || !company && setNull) {
          setCompany(company);
        }
        if (!company) {
          return of(null);
        }
        return forkJoin([
          permissionService.assignPermissionData(),
          productService.getProductsData(),
          userService.updateUser()
        ])
          .pipe(
            map(() => {
              return company;
            }),
            catchError(() => of(null)));
      })
    );
};

const updateCurrentCompany = (suppressDefaultErrorHandler?: boolean | number[]): Observable<null | Company> => getAvailableCompanyCollection({}, suppressDefaultErrorHandler)
  .pipe(
    take(1),
    map((companyCollection: Collection<Company>) => {
      const companiesList: Company[] = companyCollection.elements;
      availableCompaniesList$.next(companiesList);
      const company: null | Company = companiesList[0] || null;
      return setCompany(company);
    })
  );

export const companyService: CompanyService = {
  isCompanySelected$,
  setIsCompanySelected,
  getIsCompanySelected,

  company$,
  getCurrentCompany,
  resetCompany,

  companyId$,
  getCurrentCompanyId,

  noCompaniesAssignedMapper,
  getNoCompaniesAssigned,
  noCompaniesAssigned$,

  availableCompaniesList$,
  getAvailableCompanyCollection,
  updateAvailableCompaniesList,

  isMultitenancyMapper,
  getIsMultitenancy,
  isMultitenancy$,

  selectCurrentCompany,
  updateCurrentCompany
};
