import { staticRouteNameList } from './staticRouteNameList';

export const bothRouteNameList: string[] = [
  '@@router5/UNKNOWN_ROUTE',
  'errorPage',
  'notFound',
  'notAllowed',

  'default',

  ...staticRouteNameList,
];
