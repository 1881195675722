import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { singleInternalProductName$, getSingleInternalProductName } = productService;
export const useSingleInternalProductName = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: singleInternalProductName$,
  initialValue: getSingleInternalProductName(),
  unsubscribe$
});
