import { Subject } from 'rxjs';
import { useObservableSubscription } from '@hooks';
import { productService } from '@services';

const { ownedProducts$, getOwnedProducts } = productService;
export const useOwnedProducts = (unsubscribe$: Subject<void>) => useObservableSubscription({
  observable$: ownedProducts$,
  initialValue: getOwnedProducts(),
  unsubscribe$
});
