import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { userService } from '@services';

export const checkUserMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State, fromState: State): boolean | Promise<boolean> => {
  const routeName = toState?.name;
  if (routeName === 'login') {
    return true;
  }
  if (!fromState) {
    return userService
      .checkUser();
  }
  return true;
};
