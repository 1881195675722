import { IComponentDictionary } from '@interfaces';
export enum OrganizationTab {
  COMPANY = 'COMPANY',
  MANAGEMENT = 'MANAGEMENT',
  BUSINESS = 'BUSINESS',
  SECURITY = 'SECURITY'
}

export enum ProfileTab {
  USER = 'USER',
  SETTINGS = 'SETTINGS',
  EMAIL = 'EMAIL',
}

export type TabsRouteList = 'organization' | 'profile';
export type RouteTabMappingItem = {
  routeList: string[],
  mapper?: IComponentDictionary
};

export const routeTabMapping: Record<TabsRouteList, RouteTabMappingItem> = {
  organization: {
    routeList: Object.keys(OrganizationTab) as OrganizationTab[],
    mapper: {
      COMPANY: 'organization',
      MANAGEMENT: 'organization',
      BUSINESS: 'organization',
      SECURITY: [
        'sso',
        'ssoSelfCheckoutButton'
      ]
    }
  },
  profile: {
    routeList: Object.keys(ProfileTab) as ProfileTab[],
    mapper: {
      USER: 'profile',
      SETTINGS: 'profile',
      EMAIL: 'profile'
    }
  }
}
