import { State } from 'router5';
import { router } from '@router';

export const getRouterDataForPagePermission = (urlPath: string): State | null => {
  let routerData = router().matchUrl(urlPath);
  const routeTab = routerData?.params.routeTab;
  if (routerData && routeTab) {
    const pathRegExp = new RegExp(`^.*(?=(\/${routeTab}))`, 'i');
    const [ path ] = pathRegExp.exec(routerData.path) ?? [];
    if (path) {
      routerData = router().matchUrl(path);
    }
  }
  return routerData;
};
