import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import {
  useIsHasOfferedProducts,
  useIsHasOwnedProducts,
  useOfferedProducts,
  useOwnedProducts
} from '@hooks';
import { UseSwitcherModalOptions } from './hooks';
import ProductList from './components/ProductList/ProductList';

import styles from './ProductSwitcherContent.styl';

type ProductSwitcherModalContentProps = {
  options?: UseSwitcherModalOptions;
}
const ProductSwitcherContent: FC<ProductSwitcherModalContentProps> = ({ options }) => {
  const { t } = useTranslation('products');

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    return (): void => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);
  const isShowOwnedProducts = useIsHasOwnedProducts(unsubscribe$);
  const ownedProducts = useOwnedProducts(unsubscribe$);
  const isShowOfferedProducts = useIsHasOfferedProducts(unsubscribe$);
  const offeredProducts = useOfferedProducts(unsubscribe$);

  const ownedProductsList = isShowOwnedProducts
    ? (
      <div className={ styles.list }>
        <div className={ styles.title }>{ t('translations.owned') }</div>
        <ProductList options={ options } data={ ownedProducts }/>
      </div>
    )
    : null;

  const offeredProductsList = isShowOfferedProducts
    ? (
      <div className={ styles.list }>
        <div className={ styles.title }>{ t('translations.offered') }</div>
        <ProductList options={ options } data={ offeredProducts }/>
      </div>
    )
    : null;

  return (
    <div className={ styles.productSelect }>
      { ownedProductsList }
      { offeredProductsList }
    </div>
  );
};

export default ProductSwitcherContent;
