export const createCamelCase = (wordList: string[]): string => wordList
  .reduce(
    (result: string, word: string, index: number) => {
      const part = index
        ? word.toLowerCase().charAt(0).toUpperCase() + word.toLowerCase().slice(1)
        : word.toLowerCase();
      return `${result}${part}`;
    },
    ''
  );
