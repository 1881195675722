import { ActivationFn, ActivationFnFactory, State } from 'router5';
import {
  RouteState,
  externalRedirectStorageName,
  externalRedirectParameterName
} from '@router';

export const externalRedirectParameterHandler: ActivationFnFactory = (): ActivationFn => (toState: State): boolean => {
  const { error, name, params } = toState as RouteState;
  if (error || ['default', 'sso'].includes(name)) {
    return true;
  }
  const externalRedirectParameter = params[externalRedirectParameterName];
  if (externalRedirectParameter) {
    if ('login' === name) {
      sessionStorage.setItem(externalRedirectStorageName, externalRedirectParameter);
    } else {
      delete params[externalRedirectParameterName];
      sessionStorage.removeItem(externalRedirectStorageName);
    }
  } else {
    sessionStorage.removeItem(externalRedirectStorageName);
  }
  return true;
};
