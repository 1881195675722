import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { NewRelicService } from './newRelic.typings';

const {
  newRelicAccountId: accountID,
  newRelicAgentId: agentID,
  newRelicApplicationId: applicationID,
  newRelicLicenseKey: licenseKey,
  newRelicTrustKey: trustKey
} = (window as any).env || {};

const loader_config = {
  accountID,
  agentID,
  applicationID,
  licenseKey,
  trustKey
};
const info = {
  applicationID,
  licenseKey,
  beacon: 'bam.eu01.nr-data.net',
  errorBeacon: 'bam.eu01.nr-data.net',
  sa: 1
};
const init = {
  distributed_tracing: {
    enabled: true
  },
  privacy: {
    cookies_enabled: false
  },
  ajax: {
    deny_list: [ 'bam.eu01.nr-data.net' ]
  }
};

const options = {
  init,
  info,
  loader_config
};

const initialize = (): void => {
  if (!accountID || !agentID || !applicationID || !licenseKey || !trustKey) {
    return;
  }
  new BrowserAgent(options);
};

export const newRelicService: NewRelicService = {
  initialize
};
