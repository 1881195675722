import { ApplicationProduct, isApplicationProduct, productStorageName } from '@router';

export const getProductStorageValue = (): null | ApplicationProduct => {
  const value = sessionStorage.getItem(productStorageName);
  if (!value) {
    return null;
  }
  return isApplicationProduct(value)
    ? value
    : null;
};
