import $ from 'jquery';
import { DataFormat, GroupedDataFormat, IdTextPair, LoadingData } from 'select2';
import { isLoadingData, markMatch } from '@utilities';

export const templateResult = (placeholder: string, blocked: string, blockedTooltip: string) => (
  state: DataFormat | GroupedDataFormat | LoadingData | IdTextPair,
  term?: string
): string | JQuery => {
  const $result = $('<span class="dropdown-user-item" />');
  if (
    (isLoadingData(state) && state.loading)
    || state.id === ''
    || state.id === 'false'
    || state.text.indexOf('<') < 0
  ) {
    return $result.text(state.text);
  }
  $result.addClass('user');
  $result.attr('title', state.text);
  const array = state.text.split(' <');
  const fullName = markMatch(array[0].trim(), term);
  const email = markMatch(array[1].slice(0, -1), term);

  const $info = $('<span class="info" />');
  const info = fullName ? fullName : placeholder;
  $info.append(info);
  $info.append(' ');
  const $email = $('<span class="email" />');
  $email.append('<');
  $email.append(email);
  $email.append('>');
  $result.append($info);
  $result.append($email);
  if ((state as DataFormat).blocked) {
    const $blocked = $('<span class="blocked" />');
    $blocked.append(blocked);
    $result.append($blocked);
    $result.attr('title', blockedTooltip);
  }
  return $result;
};
