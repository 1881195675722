export interface IsInRouteNameListOptions {
  routeName?: null | string;
  routeNameList: string[];
  include?: string | string[];
  exclude?: string | string[];
}

export const isInRouteNameList = (data: IsInRouteNameListOptions): boolean => {
  const {
    routeName,
    routeNameList,
    include,
    exclude
  } = data;

  const includeList: string[] = Array.isArray(include)
    ? include
    : typeof include === 'undefined'
      ? []
      : [ include ];

  const excludeList: string[] = Array.isArray(exclude)
    ? exclude
    : typeof exclude === 'undefined'
      ? []
      : [ exclude ];

  const routeNameListFiltered = [
    ...routeNameList,
    ...includeList
  ]
    .filter((route: string): boolean => !excludeList.includes(route));

  return routeName
    ? routeNameListFiltered
      .some((item: string): boolean => item === routeName)
    : false;
};
