import { ResolverData } from '@router';

export const getEmailValue = (data: ResolverData): null | string => {
  const { email, state } = data.parameter;
  if (!email && !state) {
    return null;
  }
  return email
    ? decodeURIComponent(email)
    : decodeURIComponent(atob(state));
};
