import { IDeviceService } from './device.typings';

const getScreenDimensions = () => {
  const { screen } = window;
  const { width, height } = screen;
  return { width, height };
};
const getWindowDimensions = () => {
  const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { width, height };
};

export const deviceService: IDeviceService = {
  getScreenDimensions,
  getWindowDimensions
};
